import React, { useEffect } from "react";
import ram from "../assets/ram.png";
import logo from "../assets/logo-small.png";

const Page2Content = ({ selectedOptions, handleOptionChange }) => {
  const options = [
    "Мне это не применимо...",
    "Наверное нужно, понимание смутное...",
    "Нужна помощь в постановке задачи...",
    "Нужно решение под нашу задачу...",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Версия для мобильных экранов */}
      <div className="flex flex-col md:hidden justify-between items-center">
        <div className="flex w-full justify-between items-center mt-[20px]">
          <h1 className=" text-white font-[600] text-[24px]">
            Что вы знаете про ИИ?
          </h1>

          <div>
            <img src={logo} alt="logo" />
          </div>
        </div>

        <div className="w-[300px] mt-[46px] mb-[24px]">
          <img src={ram} alt="ram" />
        </div>
        <div className="text-white font-[400] text-[16px] mb-[16px]">
          Проникновение ИИ во все процессы сейчас такое же, как наступление эры
          электричества в начале 20 века!
        </div>
        <div className="self-start text-white font-[400] text-[16px] mb-[32px]">
          Чтобы не проиграть конкуренцию, начинать внедрять ИИ надо уже “вчера”.
        </div>
        <div>
          <div className="text-white font-[400] text-[16px] mb-[32px]">
            Посмотрите, какие сейчас есть типовые уровни понимания задач
            применения IT технологий с ИИ.
          </div>

          <div className="flex flex-col gap-[2px] max-w-[750px]">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionChange(option)}
                className={`flex items-center p-[16px]  gap-[12px] cursor-pointer border-b-[1px] border-white border-opacity-[15%] rounded-[8px] ${
                  selectedOptions[option] ? "bg-[#3A337C]" : ""
                }`}
              >
                <div
                  className={`flex flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                    selectedOptions[option] ? "bg-[#5EFF49]" : "bg-gray-300"
                  }`}
                ></div>
                <span className={`text-white font-[400] text-[16px]`}>
                  {option}
                </span>
              </div>
            ))}
          </div>

          <div className="text-white font-[400] text-[16px] mt-[32px] bg-[#1B164A] rounded-[8px] py-[16px] px-[16px] mb-[28px]">
            А у вас как? Кликните свой вариант!
          </div>
          {/* <div className="text-white font-[400] text-[12px] mb-[8px]">
            Мы и есть эти эксперты, которые помогут вам быть эффективным и
            конкурентным путем применения современных IT технологий с
            искусственным интеллектом.
          </div> */}
        </div>
      </div>

      {/* Версия для десктопа экранов */}
      <div className="hidden md:flex justify-between items-start gap-[16px]">
        <div className="max-w-[500px] mt-[20px]">
          <h1 className="text-white font-[600] text-[36px] mb-[8px]">
            Что вы знаете про ИИ?
          </h1>
          <div className="text-white font-[400] text-[18px] mb-[6px]">
            Проникновение ИИ во все процессы сейчас такое же, как наступление
            эры электричества в начале 20 века!
          </div>
          <div className="text-white font-[400] text-[18px] mb-[8px]">
            Чтобы не проиграть конкуренцию, начинать внедрять ИИ надо уже
            “вчера”.
          </div>
          <div>
            <div className="text-white font-[400] text-[24px] mb-[10px]">
              Посмотрите, какие сейчас есть типовые уровни понимания задач
              применения IT технологий с ИИ.
            </div>

            <div className="flex flex-col gap-[8px] max-w-[750px]">
              {options.map((option, index) => (
                <div
                  key={index}
                  onClick={() => handleOptionChange(option)}
                  className="flex items-center gap-[12px] cursor-pointer pb-[8px] border-b-[1px] border-white border-opacity-[15%]"
                >
                  <div
                    className={`flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                      selectedOptions[option] ? "bg-[#5EFF49]" : "bg-gray-300"
                    }`}
                  ></div>
                  <span className="text-white font-[400] text-[18px]">
                    {option}
                  </span>
                </div>
              ))}
            </div>

            <div className="text-white font-[400] text-[18px] mt-[12px] mb-[8px]">
              А у вас как? Кликните свой вариант!{" "}
            </div>
            {/* <div className="text-white font-[400] text-[16px] mb-[26px]">
              Мы и есть эти эксперты, которые помогут вам быть эффективным и
              конкурентным путем применения современных IT технологий с
              искусственным интеллектом.
            </div> */}
          </div>
        </div>
        <div>
          <img src={ram} alt="ram" className="min-w-[360px] max-w-[400px]" />
        </div>
      </div>
    </div>
  );
};

export default Page2Content;
