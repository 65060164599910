// src/pages/Page1Content.js
import React, { useEffect } from "react";
import chameleon from "../assets/chameleon.png";
import chameleon2 from "../assets/chameleon-full.png";
import logo from "../assets/logo.png";

function Page1Content() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Мобильная */}
      <div className="flex flex-col md:hidden justify-between items-center">
        <div>
          <img src={logo} alt="logo" className="my-2 mb-[45px] w-[220px]" />
        </div>
        <h1 className="text-white text-[32px] font-[600] self-start text-start mb-[24px] whitespace-nowrap">
          Приветствуем всех,
        </h1>

        <div className="w-[300px] mt-4 self-start rotate-[-30deg]">
          <img src={chameleon2} alt="chameleon" />
        </div>
        <div className="text-white text-[16px] font-[400] mb-[20px] mt-[6px]">
          <div className="mb-[16px]">
          Кто интересуется применением искусственного интеллекта (AI) для своих задач!
          </div>
          <div className="mb-[16px]">
          Мы-команда Masicteam, далее коротко расскажем, как это делать и почему это выгодно!
          </div>
          <div className="mb-[16px]">
          Официальный сайт нашей компании (МТК), участника фонда “Сколково” -  {" "}
            <a
              href="https://www.masicteam.ru/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline hover:text-blue-700 transition-colors"
            >
              ссылка
            </a>
          
          </div>
          <div className="mb-[42px]">
          Для экспертов - наш стек технологий можно посмотреть {" "}
            <a
              href="https://www.masicteam.ru"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline hover:text-blue-700 transition-colors"
            >
              тут
            </a>
          </div>
        </div>
      </div>

      {/* Версия для десктопа */}
      <div className="hidden md:flex justify-between items-center gap-[16px]">
        <div className="md:max-w-[400px] lg:max-w-[500px] xl:max-w-[640px] flex flex-col items-start justify-start mb-[22px]">
          <h1 className="text-white text-[42px] font-[600] text-start mb-[20px] mt-[42px] whitespace-nowrap sm:whitespace-normal">
            Приветствуем всех,
          </h1>
          <div className="flex flex-col gap-[12px] text-white text-[18px] font-[400] max-w-[540px]">
            <div>
              Кто интересуется применением искусственного интеллекта (AI) для
              своих задач!
            </div>
            <div>
              Мы-команда Masicteam, далее коротко расскажем, как это делать и
              почему это выгодно!{" "}
            </div>
            <div>
              Официальный сайт нашей компании (МТК), участника фонда “Сколково”
              -               {" "}
              <a
                href="https://www.masicteam.ru"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline hover:text-blue-700 transition-colors"
              >
                ссылка
              </a>
            </div>
            <div>
              Для экспертов - наш стек технологий можно посмотреть{" "}
              <a
                href="https://www.masicteam.ru"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline hover:text-blue-700 transition-colors"
              >
                тут
              </a>
            </div>
          </div>
        </div>
        <div className="md:max-w-[340px] lg:max-w-[440px]  absolute md:top-20 lg:top-[56px] left-[55%]">
          {/* <img src={chameleon} alt="chameleon" /> */}
          <img src={chameleon2} alt="chameleon" className="rotate-[-30deg]" />
        </div>
      </div>
    </div>
  );
}

export default Page1Content;
