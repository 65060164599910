// App.js
import { useState } from "react";
import "./index.css";
import Page from "./components/Page";
import Layout from "./components/Layout";

function App() {
  const [currentPage, setCurrentPage] = useState(1);

  const handleNext = () => {
    // setCurrentPage((prevPage) => prevPage + 1);
    if (currentPage < 8) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else {
      alert("Данные отправлены!");
    }
  };

  const handleBack = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  return (
    <Layout currentPage={currentPage}>
      <Page currentPage={currentPage} onNext={handleNext} onBack={handleBack} />
    </Layout>
  );
}

export default App;
