import React, { useEffect, useState } from "react";
import cheetah from "../assets/cheetah.png";
import logo from "../assets/logo-small.png";

const Page3Content = ({ handleOptionChange,  }) => {
  const options = [
    {
      title: "Больше лояльных клиентов",
      description:
        "Коммуникационный персонализированный сервис для автоматизации клиентских процессов.",
    },
    {
      title: "Уменьшили риски ошибок",
      description:
        "Добавили умный функционал на сайт/сервис/приложение, упростивший операции и контролирующий риски в процессах.",
    },
    {
      title: "Экономия на ошибках",
      description:
        "Умный функционал в CRM систему, упрощающий операции пользователей и контролирующий ошибки в процессах.",
    },
    {
      title: "Экономия на энергоресурсах",
      description:
        "Программа управления приборами и процессами, автоматизирующие сервисные операции.",
    },
    {
      title: "Экономия на рисках",
      description:
        "Вычислительный модуль, позволяющий  анализировать большие данные и просчитывать рекомендации.",
    },
    {
      title: "Эффективность персонала",
      description:
        "Рекомендательный модуль обучения новичков и поддержки типовых процессов.",
    },
    {
      title: "Прибыль с новых рынков",
      description:
        "Свой технологический стартап, расширяющий рынок или даже создающий новый.",
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionClick = (title) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(title)
        ? prevSelected.filter((option) => option !== title)
        : [...prevSelected, title]
    );
  };

  // const handleOptionClick = (title) => {
  //    handleOptionChange(3, title);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Мобильная */}
      <div className="flex flex-col md:hidden justify-between items-center">
       
      <div className="flex w-full justify-between items-center mt-[20px]">
          <h1 className=" text-white font-[600] text-[24px]">
          Кейсы применения ИИ
          </h1>

          <div>
            <img src={logo} alt="logo" />
          </div>
        </div>


        <div  className="w-[300px] mt-[32px] mb-[12px]">
          <img src={cheetah} alt="cheetah" />
        </div>
        <div className="text-white font-[400] text-[16px] mt-[8px] mb-[16px]">
        Далее типовые кейсы, благодаря которым компании получили конкурентные преимущества:
        </div>

        {/* <div className="flex flex-col gap-[12px] max-w-[800px]"> */}
        <div className="flex flex-col gap-[2px] max-w-[800px]">
          {options.map((option, index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(option.title)}
              className={`flex items-center justify-start gap-[16px] p-[16px] border-b-[1px] border-white border-opacity-[15%] cursor-pointer rounded-[8px]  ${
                selectedOptions.includes(option.title) ? "bg-[#3A337C]" : ""
                }
                `}
            >
              <div
                className={`flex-shrink-0 w-[10px] h-[10px] rounded-[2px]  ${
                  selectedOptions.includes(option.title)
                    ? "bg-[#5EFF49]"
                    : "bg-gray-300"
                }`}
              ></div>
              <div className="flex flex-col items-start">
                <div className="text-white font-[600] text-[16px]">
                  {option.title}
                </div>
                <div className="text-white font-[400] text-[13px] opacity-[40%]">
                  {option.description}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-white font-[400] text-[16px] mt-[32px] mb-[28px] bg-[#1B164A] rounded-[8px] py-[16px] px-[16px] w-full ">
          Кликните на пункты, что бы подошло для вас?
        </div>
        {/* <div className="text-white font-[400] text-[11px] mb-[10px] self-start">
          Для экспертов - наш стек технологий можно посмотреть{" "}
          <a
            href="https://example.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline hover:text-blue-700 transition-colors"
          >
            тут
          </a>
        </div> */}
      </div>
      {/* </div> */}

      {/* Десктоп */}
      <div className="hidden md:flex justify-between items-start gap-[62px]">
        <div className="max-w-[832px] mt-[20px]">
          <h1 className="text-white font-[600] text-[36px] mb-[8px]">
          Кейсы применения ИИ
          </h1>
          <div className="text-white font-[400] text-[24px] mb-[8px]">
          Далее типовые кейсы, благодаря которым компании получили конкурентные преимущества:
          </div>

          <div className="flex flex-col gap-[6px] max-w-[800px]">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option.title)}
                className={`flex items-center justify-start gap-[12px] pb-[6px] border-b-[1px] border-white border-opacity-[15%] cursor-pointer 
                  `}
              >
                <div
                  className={`flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                    selectedOptions.includes(option.title)
                      ? "bg-[#5EFF49]"
                      : "bg-gray-300"
                  }`}
                ></div>
                <div className="flex flex-col items-start">
                  <div className="text-white font-[600] text-[18px]">
                    {option.title}
                  </div>
                  <div className="text-white font-[400] text-[14px] opacity-[40%]">
                    {option.description}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="text-white font-[400] text-[18px] mt-[16px] mb-[6px]">
          Кликните на пункты, что бы подошло для вас?
          </div>
          {/* <div className="text-white font-[400] text-[13px] mb-[10px]">
            Для экспертов - наш стек технологий можно посмотреть{" "}
            <a
              href="https://example.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline hover:text-blue-700 transition-colors"
            >
              тут
            </a>
          </div> */}
        </div>
        <div>
          <img src={cheetah} alt="cheetah" className="min-w-[300px] max-w-[400px]" />
        </div>
      </div>
    </div>
  );
};

export default Page3Content;
