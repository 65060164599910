import React, { useState } from "react";
import ButtonsBlock from "../components/ButtonsBlock";
import Page1Content from "./Page1Content";
import Page2Content from "./Page2Content";
import Page3Content from "./Page3Content";
import Page4Content from "./Page4Content";
import Page5Content from "./Page5Content";
import Page6Content from "./Page6Content";
import Page7Content from "./Page7Content";
import Page8Content from "./Page8Content";
import ReCAPTCHA from "react-google-recaptcha";

const PageContent = ({ currentPage, onNext, onBack }) => {
  // const [selectedOptions, setSelectedOptions] = useState({
  //   2: {},
  //   3: [],
  //   4: {},
  //   5: {},
  //   6: {},
  //   7: {},
  //   8: {},
  // });

  const [selectedOptions, setSelectedOptions] = useState([[],[],[],[],[],[],[],[],])
  
  const [captchaToken, setCaptchaToken] = useState(null);
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const [contactData, setContactData] = useState({
    telegram: "",
    email: "",
    phone: "",
  });

  const handleOptionChange = (page, option) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [page]: {
        ...prev[page],
        [option]: !prev[page][option],
      },
    }));
  };

  const handleContactDataChange = (field, value) => {
    setContactData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const buttonTexts = {
    1: "",
    2: "Чтобы узнать, какие решения выбирают ваши конкуренты, жмите далее",
    3: "Жмите далее, чтобы узнать про доступные для вас технологии",
    4: "Жмите далее, чтобы узнать за счёт чего это так выгодно",
    5: "Жмите далее, чтобы узнать о наших реализованных проектах",
    6: "Жмите далее, чтобы узнать о нашем бесплатном предложении",
    7: "Жмите далее, чтобы узнать наши контакты для связи",
    8: "Благодарим за заполнение формы!",
  };

  // console.log(selectedOptions, contactData);
  return (
    <div>
      {currentPage === 1 && (
        <Page1Content
          selectedOptions={selectedOptions[1]}
          handleOptionChange={(option) => handleOptionChange(1, option)}
        />
      )}
      {currentPage === 2 && (
        <Page2Content
          selectedOptions={selectedOptions[2]}
          handleOptionChange={(option) => handleOptionChange(2, option)}
        />
      )}
      {currentPage === 3 && (
        <Page3Content
          selectedOptions={selectedOptions[3]}
          handleOptionChange={(option) => handleOptionChange(3, option)}
        />
      )}
      {currentPage === 4 && (
        <Page4Content
          selectedOptions={selectedOptions[4]}
          handleOptionChange={(option) => handleOptionChange(4, option)}
        />
      )}
      {currentPage === 5 && (
        <Page5Content
          selectedOptions={selectedOptions[5]}
          handleOptionChange={(option) => handleOptionChange(5, option)}
        />
      )}
      {currentPage === 6 && (
        <Page6Content
          selectedOptions={selectedOptions[6]}
          handleOptionChange={(option) => handleOptionChange(6, option)}
        />
      )}
      {currentPage === 7 && (
        <Page7Content
          selectedOptions={selectedOptions[7]}
          handleOptionChange={(option) => handleOptionChange(7, option)}
        />
      )}
      {currentPage === 8 && (
        <Page8Content
          contactData={contactData}
          onContactDataChange={handleContactDataChange}
        />
      )}

      {/* {currentPage === 8 && (
        <div className="captcha-container flex justify-start ">
          <ReCAPTCHA  sitekey="6LfaZXMqAAAAAGEPKv7o_uUwxW1Oy566LPBnccam" onChange={handleCaptchaChange} />
        </div>
      )} */}

      <ButtonsBlock
        onButtonNextClick={onNext}
        onButtonBackClick={onBack}
        isBack={currentPage > 1}
        isLastPage={currentPage === 8}
        isFirstPage={currentPage === 1}
        text={buttonTexts[currentPage]}
      />
    </div>
  );
};

export default PageContent;
