import React, { useEffect } from "react";
import dove from "../assets/dove-mobile.png";
import mail from "../assets/icon-mail.png";
import phone from "../assets/icon-phone.png";
import telegram from "../assets/icon-telegram.png";
import code from "../assets/qr-code.png";
import logo from "../assets/logo-small.png";
import masicteam from "../assets/masicteam.png";

const Page8Content = ({ contactData, onContactDataChange }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Мобильная */}
      <div className="flex flex-col md:hidden justify-between items-center">
        <div className="flex w-full justify-between items-center mt-[20px]">
          <h1 className=" text-white font-[600] text-[24px]">Контакты</h1>

          <div>
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="w-[250px] mt-[28px] mb-[20px] md:mb-[54px]">
          <img src={dove} alt="dove" />
        </div>
        <div className="flex items-start sm:items-start sm:flex-row w-full gap-[8px] xs:gap-[12px] mt-[16px] mb-[32px]">
          <img src={code} alt="qr-code" className="w-[150px] xs:w-[194px]" />

          <div className="flex flex-col w-full items-stretch gap-[9px]">
            <div className="h-[46px] flex gap-[8px] items-center justify-start bg-[#201C45] py-[12px] px-[10px] xs:px-[14px] border-[#302A6A] border-[1px] rounded-[8px]">
              <img src={phone} alt="phone" />
              <div className="flex-1 text-white font-[600] text-[13px] xs:text-[16px] whitespace-nowrap">
                +7(967) 977-88-99
              </div>
            </div>

            <div className="h-[46px] flex gap-[8px] items-center justify-start bg-[#201C45] py-[12px] px-[10px] xs:px-[14px] border-[#302A6A] border-[1px] rounded-[8px]">
              <img src={mail} alt="mail" />
              <div className="flex-1 text-white font-[600] text-[13px] xs:text-[16px]">
                info@masicteam.ru
              </div>
            </div>

            <div className="h-[46px] flex gap-[8px] items-center justify-start bg-[#201C45] py-[12px] px-[10px] xs:px-[14px] border-[#302A6A] border-[1px] rounded-[8px]">
              <img src={telegram} alt="telegram" />
              <div className="flex-1 text-white font-[600] text-[13px] xs:text-[16px]">
                @DmitryGerdelin
              </div>
            </div>

            <div className="h-[46px] flex gap-[8px] items-center justify-start bg-[#201C45] py-[12px] px-[10px] xs:px-[14px] border-[#302A6A] border-[1px] rounded-[8px]">
              <img src={masicteam} alt="masicteam" />
              <div className="flex-1 text-white font-[600] text-[13px] xs:text-[16px]">
                masicteam.ru
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col gap-[16px] ">
          <div className="w-full h-[64px] flex px-[12px] py-[6px] border-[1px] rounded-[12px]  opacity-[25%]">
            <input
              type="text"
              placeholder="Telegram"
              value={contactData.telegram}
              onChange={(e) => onContactDataChange("telegram", e.target.value)}
              className="w-full h-full outline-none bg-transparent text-white font-[400] text-[12px] "
            />
          </div>

          <div className="w-full h-[64px] flex px-[12px] py-[6px] border-[1px]  opacity-[25%] rounded-[12px]">
            <input
              type="text"
              placeholder="E-mail"
              value={contactData.email}
              onChange={(e) => onContactDataChange("email", e.target.value)}
              className="w-full h-full outline-none bg-transparent text-white font-[400] text-[12px] "
            />
          </div>

          <div className="w-full h-[64px] flex px-[12px] pt-[6px] border-[1px] opacity-[25%] rounded-[12px]">
            <input
              type="text"
              placeholder="Телефон"
              value={contactData.phone}
              onChange={(e) => onContactDataChange("phone", e.target.value)}
              className="w-full h-full outline-none bg-transparent text-white font-[400] text-[12px] "
            />
          </div>

          <div className="mb-[32px]">
            <h1 className="font-[600] text-[20px] text-white mt-[32px] mb-[11px]">
              Задача, которую требуется решить
            </h1>
            <div className="w-full h-[145px] flex px-[12px] pt-[6px] border-[1px] opacity-[25%] rounded-[12px]">
              <input
                type="text"
                placeholder="Опишите вашу задачу"
                value={contactData.phone}
                onChange={(e) => onContactDataChange("phone", e.target.value)}
                className="w-full h-full outline-none bg-transparent text-white font-[400] text-[12px] "
              />
            </div>
          </div>
        </div>
      </div>

      {/* Десктоп */}
      <div className="hidden md:flex justify-start gap-[160px] items-start">
        <div className="max-w-[832px] mt-[20px]">
          <h1 className="text-white font-[600] text-[36px] whitespace-nowrap mb-[16px]">
            Контакты
          </h1>
          {/* <h1 className="text-white font-[600] text-[18px] whitespace-nowrap mb-[12px]">
            Оставьте свои контакты
          </h1> */}

          <div className="flex flex-col gap-[12px]">
            <div className="w-full xl:w-[441px] h-[50px] flex px-[12px] py-[14px] border-[1px] rounded-[12px]">
              <input
                type="text"
                placeholder="Telegram"
                value={contactData.telegram}
                onChange={(e) =>
                  onContactDataChange("telegram", e.target.value)
                }
                className="w-full h-full outline-none bg-transparent text-white opacity-[40%] font-[400] text-[12px] "
              />
            </div>

            <div className="w-full xl:w-[441px] h-[50px] flex px-[12px] py-[14px] border-[1px] rounded-[12px]">
              <input
                type="text"
                placeholder="E-mail"
                value={contactData.email}
                onChange={(e) => onContactDataChange("email", e.target.value)}
                className="w-full h-full outline-none bg-transparent text-white opacity-[40%] font-[400] text-[12px] "
              />
            </div>

            <div className="w-full xl:w-[441px] h-[50px] flex px-[12px] py-[14px] border-[1px] rounded-[12px]">
              <input
                type="text"
                placeholder="Телефон"
                value={contactData.phone}
                onChange={(e) => onContactDataChange("phone", e.target.value)}
                className="w-full h-full outline-none bg-transparent text-white opacity-[40%] font-[400] text-[12px] "
              />
            </div>
          </div>
          <div className="flex w-full gap-[12px] mt-[18px] mb-[10px]">
            <img src={code} alt="qr-code" className="max-w-[126px]" />

            <div className="flex w-full min-w-[220px] flex-col items-stretch gap-[8px]">
              <div className="h-[40px] flex gap-[8px] items-center justify-start bg-[#201C45] py-[12px] px-[14px] border-[#302A6A] border-[1px] rounded-[8px]">
                <img src={phone} alt="phone" />
                <div className="flex-1 text-white font-[600] text-[14px] whitespace-nowrap">
                  +7(967) 977-88-99
                </div>
              </div>

              <div className="h-[40px] flex gap-[8px] items-center justify-start bg-[#201C45] py-[12px] px-[14px] border-[#302A6A] border-[1px] rounded-[8px]">
                <img src={mail} alt="mail" />
                <div className="flex-1 text-white font-[600] text-[14px]">
                  info@masicteam.ru
                </div>
              </div>

              <div className="h-[40px] flex gap-[8px] items-center justify-start bg-[#201C45] py-[12px] px-[14px] border-[#302A6A] border-[1px] rounded-[8px]">
                <img src={telegram} alt="telegram" />
                <div className="flex-1 text-white font-[600] text-[14px]">
                  @DmitryGerdelin
                </div>
              </div>
            </div>
          </div>
          <div className="mb-[133px]">
            <h1 className="font-[600] text-[20px] text-white mt-[20px] mb-[11px]">
              Задача, которую требуется решить
            </h1>
            <div className="w-full h-[145px] flex px-[12px] pt-[6px] border-[1px] opacity-[25%] rounded-[12px]">
              <input
                type="text"
                placeholder="Опишите вашу задачу"
                value={contactData.phone}
                onChange={(e) => onContactDataChange("phone", e.target.value)}
                className="w-full h-full outline-none bg-transparent text-white font-[400] text-[12px] "
              />
            </div>
          </div>
        </div>
        <div>
          <img src={dove} alt="dove" className="min-w-[300px] max-w-[390px]" />
        </div>
      </div>
    </div>
  );
};

export default Page8Content;
