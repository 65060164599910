// src/components/Layout.js
import React from "react";
import logo from "../assets/logo.png";

const Layout = ({ children, currentPage }) => {
  const barWidths = {
    1: { left: "0%", right: "100%" },
    2: { left: "15%", right: "85%" },
    3: { left: "30%", right: "70%" },
    4: { left: "45%", right: "55%" },
    5: { left: "60%", right: "40%" },
    6: { left: "75%", right: "25%" },
    7: { left: "90%", right: "10%" },
    8: { left: "100%", right: "0%" },
  };

  return (
    <div className="min-h-screen overflow-y-hidden w-full bg-[#1A1738] pb-[12px] md:pb-[32px] relative">
      <div className="max-w-[1440px] m-auto ">
        {currentPage !== 1 && (
          <div className="md:hidden flex h-[18px] w-full">
            <div
              style={{
                width: barWidths[currentPage]?.left || "50%",
                backgroundColor: "#24FF50",
              }}
            />
            <div
              style={{
                width: barWidths[currentPage]?.right || "50%",
                backgroundColor: "#2D2863",
              }}
            />
          </div>
        )}

        <div className="pt-[12px] md:pt-[26px] mx-[24px] md:mx-[130px]">
          <img src={logo} alt="Logo" className="hidden md:block" />

          <main className="flex-1 justify-center w-full max-w-screen-xl">{children}</main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
