import React, { useEffect, useState } from "react";
import owl from "../assets/owl.png";
import logo from "../assets/logo-small.png";

const Page4Content = ({ handleOptionChange }) => {
  const options = [
    {
      title: "Компьютерное зрение",
      description: "распознавание, классификация, сегментация",
    },
    {
      title: "Экспертная аналитика",
      description: "системы поддержки принятия решений",
    },
    {
      title: "Языковые модели",
      description: "распознавание, управление, оптимизация",
    },
    {
      title: "Цифровые помощники",
      description: "быстрые ответы, генерация текстов, изображений",
    },
    {
      title: "Персонификация операций",
      description: "аналитика поведения целевой аудитории",
    },
    {
      title: "Интеграционные решения",
      description: "WEB сервисы, мобильные приложения, модули расширения",
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleOption = (title) => {
    handleOptionChange((prevOptions) => ({
      ...prevOptions,
      [title]: !prevOptions[title],
    }));
  };

  const handleOptionClick = (title) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(title)
        ? prevSelected.filter((option) => option !== title)
        : [...prevSelected, title]
    );
  };

  return (
    <div>
      {/* Мобильная */}
      <div className="flex flex-col md:hidden justify-between items-center">
        <div className="flex w-full justify-between items-center mt-[20px]">
          <h1 className=" text-white font-[600] text-[24px]">Технологии ИИ</h1>

          <div>
            <img src={logo} alt="logo" />
          </div>
        </div>

        <div className="w-[300px] mt-[20px] mb-[32px]">
          <img src={owl} alt="owl" />
        </div>
        <div className=" text-white font-[400] text-[16px] mb-[16px] mt-[8px]">
          Вам мы можем предложить следующие решения:
        </div>

        <div className="flex justify-center gap-[10px] w-full flex-wrap">
          {options.map((option, index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(option.title)}
              className={`flex flex-col items-start justify-start w-[48%] bg-[#15142A] p-[16px] rounded-[8px] cursor-pointer ${
                selectedOptions.includes(option.title)
                  ? "bg-[#3A337C]"
                  : "bg-[#15142A]"
              }`}
            >
              <div className="text-white text-[16px] font-[600]">
                {option.title}
              </div>
              <div className="text-white text-[13px] font-[400] opacity-[40%]">
                {option.description}
              </div>
            </div>
          ))}
        </div>

        <div className="text-white font-[400] text-[16px] mt-[16px] mb-[20px] bg-[#1B164A] rounded-[8px] py-[16px] px-[16px] w-full ">
          Кликните, что было бы актуально для вас!
        </div>
      </div>

      {/* Десктопная */}
      <div className="hidden md:flex justify-between items-start gap-[16px]">
        <div className="mt-[20px]">
          <h1 className="text-white font-[600] text-[36px] mb-[0px]">
            Технологии ИИ
          </h1>
          <div className="max-w-[658px] text-white font-[500] text-[24px] mb-[14px]">
            Вам мы можем предложить следующие решения:
          </div>

          <div className="flex justify-start gap-[16px] w-full flex-wrap">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option.title)}
                className={`flex flex-col items-start justify-end h-[194px] w-[260px] bg-[#15142A] p-[16px] rounded-[8px] cursor-pointer ${
                  selectedOptions.includes(option.title)
                    ? "bg-gray-600"
                    : "bg-[#15142A]"
                }`}
              >
                <div className="text-white text-[20px] font-[600]">
                  {option.title}
                </div>
                <div className="text-white text-[12px] font-[400] opacity-[40%]">
                  {option.description}
                </div>
              </div>
            ))}
          </div>

          <div className="max-w-[658px] text-white font-[400] text-[18px] mt-[16px] mb-[12px]">
            Кликните, что было бы актуально для вас!
          </div>
        </div>
        <div>
          <img src={owl} alt="owl" className="min-w-[400px]" />
        </div>
      </div>
    </div>
  );
};

export default Page4Content;
