import React from "react";

const ButtonsBlock = ({
  onButtonNextClick,
  onButtonBackClick,
  isBack,
  text,
  isLastPage,
  isFirstPage,
}) => {
  const isCentered = isFirstPage || isLastPage;
  return (
    <div
      className={`flex mb-2 flex-col items-center  ${
        isCentered
          ? "relative md:absolute md:bottom-6 md:left-1/2 md:transform md:-translate-x-1/2"
          : "relative md:absolute md:bottom-6 md:right-[130px]"
      }`}
    >
      <div
        className={`w-full flex flex-col ${
          isFirstPage ? "items-center" : "items-end"
        } gap-[8px]`}
      >
        <p className="self-center md:self-end text-white font-[400] text-[12px] md:text-[14px] opacity-[40%] text-center md:mb-[8px] mb-[8px]">
          {text}
        </p>

        <div className="flex w-full flex-col md:flex-row md:w-auto justify-stretch items-stretch md:items-center gap-[12px] md:gap-[36px]">
          {isBack && (
            <button
              onClick={onButtonBackClick}
              className="order-2 md:order-1 py-[16px] font-[600] text-[18px] md:text-[18px] h-[50px]  basis-1/2 md:w-[190px] text-[#FFFFFF66] hover:text-[#ffffff2d] transition-all"
            >
              Назад
            </button>
          )}
          <button
            onClick={onButtonNextClick}
            className={`order-1 md:order-2 py-[16px] px-6 min-h-[50px]  font-[600] text-[18px] md:text-[18px] bg-gradient-to-r from-[#0852FF] via-[#2C67F2] to-[#0852FF] border border-[#3E7BFF] text-white rounded-full md:rounded-lg transition-all ${
              isBack ? "basis-1/2 md:w-[260px]" : "w-full mx-auto md:w-[300px]"
            }`}
          >
            {isLastPage ? "Отправить" : "Далее"}
          </button>
        </div>

        {/* <div className="flex w-full flex-col md:flex-row md:w-auto justify-stretch items-stretch md:items-center gap-4 md:gap-[36px]">
          {isBack && (
            <button
              onClick={onButtonBackClick}
              className=" font-[600] text-[18px] md:text-[18px] h-[50px] md:h-[55px] basis-1/2 md:w-[190px] text-[#FFFFFF66] hover:text-[#ffffff2d] transition-all"
            >
              Назад
            </button>
          )}
          <button
            onClick={onButtonNextClick}
            className={`px-6 min-h-[50px] md:h-[55px] font-[600] text-[18px] md:text-[18px] bg-gradient-to-r from-[#0852FF] via-[#2C67F2] to-[#0852FF] border border-[#3E7BFF] text-white rounded-full md:rounded-lg transition-all ${
              isBack ? "basis-1/2 md:w-[260px]" : "w-full mx-auto md:w-[300px]"
            }`}
          >
            {isLastPage ? "Отправить" : "Далее"}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ButtonsBlock;
