import React, { useEffect } from "react";
import turtle from "../assets/turtle.png";
import logo from "../assets/logo-small.png";

const Page7Content = ({ selectedOptions, handleOptionChange }) => {
  const options = [
    "Изучение специфики и формулировка задачи",
    "Анализ эффективность существующих инструментов",
    "Проект ТЗ и технологический эскиз  решения задачи",
    "Оценка рисков и трудоемкости задач по блокам",
    "Формулировка требований к данным, среде и пр.",
    "Оформление в виде понятного документа",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Мобильная */}
      <div className="flex flex-col md:hidden justify-between items-center">
        <div className="flex w-full justify-between items-center mt-[20px]">
          <h1 className=" text-white font-[600] text-[24px]">
            Персональное предложение
          </h1>

          <div>
            <img src={logo} alt="logo" />
          </div>
        </div>

        <div className="w-[300px]  my-[32px]">
          <img src={turtle} alt="turtle" />
        </div>
        <div className="text-white font-[400] text-[16px] max-w-[672px] mt-[6px] mb-[30px]">
          Мы бесплатно создаем для вас персональный проект IT решения вместе с
          оценкой эффективности и бизнес-планом!
        </div>

        <div className="flex flex-col items-start self-start">
          <div className="text-white font-[400] text-[16px] mb-[12px]">
            Это серьезная инженерно-продуктовая задача, куда входит:
          </div>

          <div className="flex flex-col items-start gap-[2px] self-start">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionChange(option)}
                className={`flex items-center p-[16px] rounded-[8px] w-full gap-[12px] cursor-pointer py-[16px] border-b-[1px] border-white border-opacity-[15%] ${
                  selectedOptions[option] ? "bg-[#3A337C]" : ""
                }
                  `}
              >
                <div
                  className={`flex flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                    selectedOptions[option] ? "bg-[#5EFF49]" : "bg-gray-300"
                  }`}
                ></div>
                <span className="text-white font-[400] text-[16px]">
                  {option}
                </span>
              </div>
            ))}
          </div>

          <div className="text-white font-[400] text-[16px] mt-[16px] bg-[#1B164A] rounded-[8px] py-[16px] mb-[48px] px-[16px] w-full ">
            Кликнете, что вам было бы интересно
          </div>
          {/* <div className="text-white font-[600] text-[14px] mt-[8px] mb-[9px]">
            Задача, которую требуется решить
          </div> */}
        </div>
        {/* <div className="w-full h-[42px] flex px-[12px] py-[12px] gap-[10px] border-[1px] rounded-[12px] mb-[18px]">
          <input
            type="text"
            placeholder="Опишите вашу задачу"
            className="w-full h-full outline-none bg-transparent text-white opacity-[40%] font-[400] text-[11px] "
          />
        </div> */}
      </div>

      {/* Десктоп */}
      <div className="hidden md:flex justify-between items-start gap-[32px]">
        <div className="max-w-[510px] mt-[20px]">
          <h1 className="text-white font-[600] text-[36px] mb-[8px]">
            Персональное предложение
          </h1>

          <div className="text-white font-[400] text-[18px] max-w-[500px] mb-[12px]">
            Мы бесплатно создаем для вас персональный проект IT решения вместе с
            оценкой эффективности и бизнес-планом!
          </div>

          <div className="text-white font-[600] text-[24px] mb-[6px]">
            Это серьезная инженерно-продуктовая задача, куда входит:
          </div>

          <div className="flex flex-col items-start self-start max-w-[490px]">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionChange(option)}
                className="flex w-full items-center gap-[12px] cursor-pointer py-[6px] border-b-[1px] border-white border-opacity-[15%]"
              >
                <div
                  className={`flex flex-shrink-0 w-[10px] h-[10px] rounded-[2px] ${
                    selectedOptions[option] ? "bg-[#5EFF49]" : "bg-gray-300"
                  }`}
                ></div>
                <span className="text-white font-[400] text-[18px]">
                  {option}
                </span>
              </div>
            ))}
          </div>

          {/* <div className="text-white font-[600] text-[16px] mt-[12px] mb-[9px]">
            Задача, которую требуется решить
          </div>

          <div className="w-full xl:w-[490px] h-[80px] flex px-[12px] py-[8px] gap-[10px] border-[1px] rounded-[12px] mb-[14px]">
            <input
              type="text"
              placeholder="Опишите вашу задачу"
              className="w-full h-full outline-none bg-transparent text-white opacity-[40%] font-[400] text-[12px] "
            />
          </div> */}

          <div className="text-white font-[400] text-[18px] mt-[10px] mb-[9px]">
            Кликните в списке, какие из приведенных целей актуальны для вас?
          </div>
        </div>
        <div>
          <img
            src={turtle}
            alt="turtle"
            className="min-w-[360px] max-w-[450px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Page7Content;
